<div *ngIf="currProfile | async as profile">
  <span
    *ngIf="question.type_of_question === 'text' || question.type_of_question === 'textarea' ||  question.type_of_question === 'select' || question.type_of_question === 'radio'">
    <div *ngFor="let answer of question.answer.text_answer" [innerHTML]="answer | newLineRegex:['\r\n', '\r', '\n']">
    </div>
  </span>
  <span *ngIf="question.type_of_question === 'amount'">
    <div *ngFor="let answer of question.answer.text_answer" [innerHTML]="answer | currency">
    </div>
  </span>
  <span *ngIf="question.type_of_question === 'country'">
    <div *ngFor="let answer of question.answer.text_answer" [innerHTML]="(answer | country) | async">
    </div>
  </span>
  <span *ngIf="question.type_of_question === 'check'">
      <div *ngFor="let offered_answer of question.offered_answers">
        <div>
          <span *ngIf="question.answer.text_answer.indexOf(offered_answer) !== -1" class="text-success">
            <fa-icon [classes]="['fa-fw']" icon="check"></fa-icon>
          </span>
          <span *ngIf="question.answer.text_answer.indexOf(offered_answer) === -1" class="text-danger">
            <fa-icon [classes]="['fa-fw']" icon="times"></fa-icon>
          </span>
          <span>{{offered_answer}}</span>
        </div>
    </div>
  </span>
  <span *ngIf="question.type_of_question === 'slider'">
    <div *ngFor="let answer of question.answer.text_answer">
      <div *ngIf="answer">
      <span *ngIf="answer === 'true'" class="text-success">
        <fa-icon [classes]="['fa-fw']" icon="check"></fa-icon>
      </span>
      <span *ngIf="answer === 'false'" class="text-danger">
        <fa-icon [classes]="['fa-fw']" icon="times"></fa-icon>
      </span>
      <span>{{question.offered_answers[0]}}</span>
    </div>
    </div>
  </span>
  <div *ngIf="question.type_of_question === 'contact'">
    <div *ngFor="let answer of question.answer.contact_answer" class="mt-2">
      <div>{{answer.name}}</div>
      <div class="font-italic">{{answer.type}}</div>
      <div>
        <a [href]="'mailto:' + answer.email">
          <fa-icon [classes]="['fa-fw']" [icon]="['far', 'envelope']"></fa-icon>
          {{answer.email}}
        </a>
      </div>
      <div>
        <a [href]="'tel:' + answer.phone">
          <fa-icon [classes]="['fa-fw']" [icon]="'phone'"></fa-icon>
          {{answer.phone}}
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="question.type_of_question === 'address'">
    <div *ngFor="let answer of question.answer.address_answer" class="mt-2">
      <div>{{answer.street}}</div>
      <div>{{answer.city}}</div>
      <div>{{(answer.country | country) | async}}</div>
      <div>{{(answer.state | state:answer.country)}}</div>
      <div>{{answer.zip}}</div>
    </div>
  </div>
  <div *ngIf="question.type_of_question === 'file'">
    <div *ngFor="let part of question.answer.file_answer; let i = index">
      {{trimTimestampFromFilename(part)}}
      <a href="javascript:void(0)" (click)="downloadFile(part)">
        <fa-icon [classes]="['fa-fw']" icon="download"></fa-icon>
      </a>
      <!-- Upload to Braid -->
      @if (profile.user.isAdministrator && isQuestionnaireAllowedForBraid()) {
        @if (isUploadedToBraid(part, false)) {
          <span class="text-green" ngbTooltip="{{'shared.answer-view: Uploaded to Braid' | translate}}">
            <fa-icon [classes]="['fa-fw']" icon="check"></fa-icon>
          </span>
        } @else {
          <a [ngClass]="{'clickable': !uploadingToBraid().status}">
            @if (part === uploadingToBraid().file && uploadingToBraid().status) {
              @if (uploadingToBraid().forFiBusiness) {
                <fa-icon [classes]="['fa-fw']" icon="upload" class="text-muted"></fa-icon>
              } @else {
                <a class="inline-block ld ld-ring ld-spin"></a>
              }
            } @else {
              <span (click)="uploadToBraid(part, false)">
                <fa-icon ngbTooltip="{{'shared.answer-view: Upload to Braid' | translate}}"
                        *ngIf="!uploadingToBraid().status"
                        [classes]="['fa-fw']" icon="upload" class="text-purple-ionfi"></fa-icon>
                <fa-icon *ngIf="uploadingToBraid().status" class="text-muted"
                        [classes]="['fa-fw']" icon="upload"></fa-icon>
              </span>
            }
          </a>
        }
        <!-- for Business FI Record -->
        @if (isQuestionnaireAllowedForBraidFIBusiness()) {
          @if (isUploadedToBraid(part,true)) {
            <span class="text-green" ngbTooltip="{{'shared.answer-view: Uploaded to Braid FI Business' | translate}}">
            <fa-icon [classes]="['fa-fw']" icon="check" class="text-color-braid-ubo"></fa-icon>
          </span>
          } @else {
            <a [ngClass]="{'clickable': !uploadingToBraid().status}">
              @if (part === uploadingToBraid().file && uploadingToBraid().status) {
                @if (!uploadingToBraid().forFiBusiness) {
                  <fa-icon class="text-muted text-color-braid-ubo" [classes]="['fa-fw']" icon="upload"></fa-icon>
                } @else {
                  <a class="inline-block ld ld-ring ld-spin text-color-braid-ubo"></a>
                }
              } @else {
                <span (click)="uploadToBraid(part, true)">
                  <fa-icon ngbTooltip="{{'shared.answer-view: Upload to Braid FI Business' | translate}}"
                           *ngIf="!uploadingToBraid().status"
                           [classes]="['fa-fw']" icon="upload" class="text-color-braid-ubo"></fa-icon>
                  <fa-icon *ngIf="uploadingToBraid().status" class="text-muted"
                           [classes]="['fa-fw']" icon="upload"></fa-icon>
                </span>
              }
            </a>
          }
        }
      }
    </div>
  </div>
  <div *ngIf="question.type_of_question === 'date'">
    <div *ngFor="let answer of question.answer.text_answer">
      {{formatDate(answer)}}
    </div>
  </div>
</div>
