import {Injectable} from '@angular/core';
import {Profile} from '@shared/models/profile';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, distinctUntilChanged, map, shareReplay, switchMap} from 'rxjs/operators';
import {ApiService} from '../services/api.service';
import {RxUtilsService} from '../services/rx-utils.service';
import {Router} from '@angular/router';
import {AlertsService} from '@app/core-module/services/alerts.service';

@Injectable()
export class CurrentProfileState {

  private currentProfile: Observable<Profile>;
  private reading: Observable<boolean>;

  private reload$ = new BehaviorSubject(true);

  constructor(private apiService: ApiService,
              private rxUtilsService: RxUtilsService,
              private router: Router,
              protected alertsService: AlertsService) {


    const initiator = this.reload$;

    const nonNullCurrentProfile = this.reload$.pipe(
      switchMap(() => this.apiService.getCurrentProfile().pipe(
        catchError(() => {
          if (!this.router.url.includes('/auth/login')) {
            this.alertsService.addAlert({type: 'danger', message: 'Error while reading current user!'});
          }
          return of(null);
        }),
      )),
      shareReplay(1)
    );
    this.currentProfile = nonNullCurrentProfile.pipe(
      shareReplay(1)
    );

    this.reading = this.rxUtilsService.createReadingInfo(initiator, this.currentProfile);
  }

  getCurrentProfile(): Observable<Profile | null> {
    return this.currentProfile;
  }

  getCurrentProfileId(): Observable<number | null> {
    return this.getCurrentProfile().pipe(
      map(user => user ? user.id : null),
      distinctUntilChanged()
    );
  }

  /**
   * Returns Observable emitting only true of false, never null or an error.
   */
  getReadingCurrentProfile(): Observable<boolean> {
    return this.reading.pipe(distinctUntilChanged());
  }

  reload() {
    this.reload$.next(true);
  }
}
